.App-logo {
  height: 60px;
  pointer-events: none;
}

.app-content {
  background-color: #faecd4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.main-container {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  background: linear-gradient(rgb(229 249 197), rgba(255, 255, 255, 1));
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
